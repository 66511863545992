import React from "react"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import textBlockSerializer from "./textBlockSerializer"
import PostGrid from "../global/PostGrid"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion"
import { Box } from "@chakra-ui/layout"
const urlFor = source =>
  imageUrlBuilder({ projectId: "2aoj8j2d", dataset: "gastrologik" })
    .image(source)
    .width(800)
    .height(450)
    .minHeight(450)
    .quality(50)
    .format("webp")

const serializers = {
  marks: {
    internalLink: ({ children, mark }) => {
      return <Link to={`/${mark.reference.slug.current}`}>{children[0]}</Link>
    },
  },

  types: {
    accordion: ({ node: { subject } }) => {
      return (
        <Accordion allowMultiple>
          {subject.map((item, i) => {
            return (
              <AccordionItem key={item.title + i}>
                <h2 style={{ margin: 0 }}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {item.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <BlockContent
                    blocks={item.description}
                    projectId="2aoj8j2d"
                    dataset="gastrologik"
                    serializers={textBlockSerializer}
                    className="recipeBlockContent"
                  />
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      )
    },

    categoryGrid: ({ node: { title, categories } }) => {
      return (
        <div style={{ margin: "50px 0px -20px" }}>
          <div className="titleLine">
            <h2 className="">{title}</h2>
          </div>
          <PostGrid
            posts={categories}
            extraClass={"shrinkImages"}
            slugPrefix={"kategori"}
          />
        </div>
      )
    },

    figure: ({ node: { image, alt } }) => (
      <img
        width="100%"
        src={urlFor(image.asset).url()}
        alt={alt + " | Gastrologik"}
      />
    ),

    interactiveElement: node => {
      return (
        <div className="interactiveContainer">
          <div className="floatingID"></div>
          <h2>{node.node.title}</h2>
          <BlockContent
            blocks={node.node.description}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={textBlockSerializer}
            className="recipeBlockContent"
          />
        </div>
      )
    },
  },
}

export default serializers
