import React from "react"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react"
import { Link } from "gatsby"

const urlFor = source =>
  imageUrlBuilder({ projectId: "2aoj8j2d", dataset: "gastrologik" })
    .image(source)
    .width(800)
    .height(450)

const serializers = {
  marks: {
    internalLink: ({ children, mark }) => {
      return <Link to={`/${mark.reference.slug.current}`}>{children[0]}</Link>
    },
  },

  types: {
    figure: ({ node: { image, alt }, node }) => (
      <img src={urlFor(image.asset).url()} alt={alt + " | Gastrologik"} />
    ),
    image: () => <></>,
    interactiveElement: () => <></>,

    accordionItem: ({ node }) => {
      return (
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {node.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <BlockContent
              blocks={node.description}
              projectId="2aoj8j2d"
              dataset="gastrologik"
              className="recipeBlockContent"
            />
          </AccordionPanel>
        </AccordionItem>
      )
    },
  },
}
export default serializers
