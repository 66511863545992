import React from "react"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import accordionBlockSerializer from "./accordionBlockSerializer"
import { Accordion } from "@chakra-ui/react"
const urlFor = source =>
  imageUrlBuilder({ projectId: "2aoj8j2d", dataset: "gastrologik" })
    .image(source)
    .width(800)
    .height(450)

const serializers = {
  types: {
    figure: ({ node: { image, alt }, node }) => (
      <img src={urlFor(image.asset).url()} alt={alt + " | Gastrologik"} />
    ),
    image: () => <></>,
    interactiveElement: () => <></>,
    accordion: node => {
      let nodeArray = []
      node.node.subject.forEach(s => {
        nodeArray.push(s)
      })
      return (
        <Accordion allowToggle mt={"20px"}>
          <BlockContent
            blocks={nodeArray}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={accordionBlockSerializer}
            className="recipeBlockContent"
          />
        </Accordion>
      )
    },
  },
}
export default serializers
