import React from "react"

import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import { graphql, Link } from "gatsby"
// import CategoryBox from "../components/pages/recipes/CategoryBox"
import PostGrid from "../components/global/PostGrid"
import { Button } from "@chakra-ui/button"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"

export default function BlogPage({ data }) {
  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          data.sanityPages.seo.seoTitle !== ""
            ? data.sanityPages.seo.seoTitle
            : "Gatrologik | Mad med mening"
        }
        description={
          data.sanityPages.seo.description !== ""
            ? data.sanityPages.seo.description
            : "Gatrologik | Mad med mening"
        }
      />

      <div className="titleWrapper">
        <h1>{data.sanityPages.pageTitle}</h1> <div className="spanLine"></div>{" "}
        <div style={{ textAlign: "center" }}>
          <Link to="/alle-opslag/">
            <Button
              variant="ghost"
              color="var(--primary-color)"
              size="sm"
              mt="2"
              mb="50px"
            >
              Se alle opslag
            </Button>
          </Link>
        </div>
        {data.sanityPages._rawBody && (
          <BlockContent
            blocks={data.sanityPages._rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent"
          />
        )}
      </div>
      <div className="titleLine">
        <h2>Alle kategorier</h2>
      </div>

      <PostGrid
        posts={data.allSanityCategories.nodes}
        extraClass={"shrinkImages"}
        slugPrefix={"kategori"}
      />
    </Layout>
  )
}

export const query = graphql`
  query postsPageQuery {
    sanityPages(slug: { current: { eq: "blog" } }) {
      pageTitle
      _rawBody(resolveReferences: { maxDepth: 10 })

      seo {
        seoTitle
        seoDescription
      }
    }
    allSanityCategories(
      sort: { tags: ASC }
      filter: { parentCategory: { eq: "posts" } }
    ) {
      nodes {
        tags
        image {
          asset {
            gatsbyImageData(
              width: 360
              height: 240
              placeholder: NONE
              backgroundColor: "#f9f9f9"
              formats: AVIF
            )
          }
        }
        slug {
          current
        }
      }
    }
  }
`
